import { FC } from "react";

interface CoBrandLogoProps {
    className?: string;
    logoUrl: string;
    navUrlHost?: string;
}

const CoBrandLogo: FC<CoBrandLogoProps> = (props: CoBrandLogoProps) => {
    return <img className={props.className || ""} src={props.logoUrl} alt={props.navUrlHost || "Brand Logo"} style={{ maxHeight: "26px" }} />;
};

export default CoBrandLogo;
